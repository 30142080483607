import { useCallback, useEffect, useState } from "react";
import { Dropdown, Icon, Loader } from "semantic-ui-react";
import { LogoutModal } from "./LogoutModal";
import { AccountModal } from "./AccountModal";

type AppState =
  | { type: "loading" }
  | {
      type: "success";
      data:
        | {
            type: "authenticated";
            user: {
              id: string;
              username: string;
            };
            songs: {
              song: string;
              artist: string;
              releasedAt?: string;
              imageUrl?: string;
              spotifyUrl?: string;
              karafunUri: string;
              isRecent: boolean;
            }[];
            activeJobs: { id: string; type: string }[];
          }
        | { type: "unauthenticated" };
    }
  | { type: "error"; message: string };

function App() {
  const [state, setState] = useState<AppState>({ type: "loading" });
  const [activeModal, setActiveModal] = useState<null | "logout" | "account">(
    null
  );

  const loadState = useCallback(() => {
    Promise.resolve()
      .then(async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const response = await fetch(`/api/state?${urlParams.toString()}`);
        const newData = await response.json();
        setState({ type: "success", data: newData });
      })
      .catch((e) => {
        setState({ type: "error", message: e.message });
      });
  }, []);

  useEffect(() => loadState(), [loadState]);

  useEffect(() => {
    if (
      state.type === "success" &&
      state.data.type === "authenticated" &&
      state.data.activeJobs.length > 0
    ) {
      const interval = setInterval(loadState, 2000);
      return () => clearInterval(interval);
    }
  }, [state, loadState]);

  if (state.type === "loading") {
    return <div className="App"></div>;
  }

  if (state.type === "success" && state.data.type === "authenticated") {
    const { data } = state;
    return (
      <div className="App" style={{ paddingBottom: "10px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            padding: "15px",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", columnGap: "10px" }}
          >
            <img src={"/singable.png"} alt="logo" style={{ height: "50px" }} />
            <h4 style={{ margin: 0 }}>Singable</h4>
          </div>
          <Dropdown icon="ellipsis vertical" direction="left">
            <Dropdown.Menu>
              <Dropdown.Item
                icon="user"
                text={data.user.username}
                onClick={() => setActiveModal("account")}
              />
              <Dropdown.Divider />
              <Dropdown.Item
                icon="sync"
                text="Rescan library"
                onClick={() => {
                  fetch("/api/rescan", { method: "post" }).then(async () => {
                    // Wait a second for the job to get picked up
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                    loadState();
                  });
                }}
              />
              <Dropdown.Item
                icon="list"
                text="Export to Playlist"
                onClick={() => {
                  fetch("/api/export-playlist", { method: "post" }).then(
                    async () => {
                      // Wait a second for the job to get picked up
                      await new Promise((resolve) => setTimeout(resolve, 1000));
                      loadState();
                    }
                  );
                }}
              />
              <Dropdown.Item
                icon="users"
                text="Copy Friend Code"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://singable.live?withFriend=${data.user.id}`
                  );
                }}
              />
              <Dropdown.Divider />
              <Dropdown.Item
                icon="privacy"
                text="Privacy Policy"
                onClick={() => (window.location.href = "/privacy.html")}
              />
              <Dropdown.Item
                icon="money bill alternate outline"
                text="Donate"
                onClick={() =>
                  (window.location.href =
                    "https://venmo.com?txn=pay&recipients=Nic-Manoogian")
                }
              />
              <Dropdown.Item
                icon="sign-out"
                text="Logout"
                onClick={() => setActiveModal("logout")}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {data.activeJobs.length > 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Loader
                active
                inline
                size="small"
                style={{ marginRight: "10px" }}
              />
              {data.activeJobs
                .map((j) =>
                  j.type === "scanSpotify"
                    ? "Scan in Progress"
                    : j.type === "exportPlaylist"
                    ? "Playlist Creation in Progress"
                    : "Background Operation In Progress"
                )
                .join(", ")}
            </div>
          </div>
        )}
        <div
          style={{
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "10px 20px 0",
          }}
        >
          <div style={{ width: "100%", maxWidth: "800px" }}>
            <img
              src={"/spotify-logo.png"}
              alt="logo"
              style={{ width: "70px" }}
            />
          </div>
          {data.songs.map((song) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "10px",
                  width: "100%",
                  maxWidth: "800px",
                  padding: "10px 0",
                  borderBottom: "1px solid #6b6b6b",
                }}
              >
                {song.imageUrl ? (
                  <img
                    src={song.imageUrl}
                    alt="album art"
                    style={{ width: "40px" }}
                  />
                ) : (
                  <div
                    style={{
                      width: "40px",
                      background: "#737373",
                    }}
                  />
                )}
                <div>
                  <div>
                    {song.isRecent && (
                      <Icon
                        name="star"
                        color="yellow"
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    {song.song}
                  </div>
                  <div style={{ color: "#d0d0d0" }}>{song.artist}</div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    columnGap: "10px",
                  }}
                >
                  <div>
                    {song.releasedAt
                      ? new Date(song.releasedAt).getFullYear()
                      : ""}
                  </div>
                  <Dropdown icon="ellipsis vertical" direction="left">
                    <Dropdown.Menu>
                      <Dropdown.Item
                        icon="music"
                        text="Open in Spotify"
                        disabled={!song.spotifyUrl}
                        onClick={() => {
                          if (!song.spotifyUrl) {
                            return;
                          }
                          window.location.href = song.spotifyUrl;
                        }}
                      />
                      <Dropdown.Item
                        icon="microphone"
                        text="Open in Karafun"
                        onClick={() => {
                          window.location.href = song.karafunUri;
                        }}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            );
          })}
        </div>
        <LogoutModal
          open={activeModal === "logout"}
          onFinish={() => loadState()}
          onCancel={() => setActiveModal(null)}
        />
        <AccountModal
          open={activeModal === "account"}
          currentUsername={data.user.username}
          onFinish={() => {
            setActiveModal(null);
            loadState();
          }}
          onCancel={() => setActiveModal(null)}
        />
      </div>
    );
  }

  return (
    <div
      className="App"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "30px",
          width: "85%",
          maxWidth: "500px",
          justifyContent: "center",
          backgroundColor: "#323337",
          border: "1px solid #585858",
          borderRadius: "8px",
        }}
      >
        <img
          src={"/singable.png"}
          alt="logo"
          style={{ height: "100px", display: "inline", marginRight: "10px" }}
        />
        <h1 style={{ margin: 0 }}>Singable</h1>
        {state.type === "error" ||
          (state.type === "success" && state.data.type === "unauthenticated" && (
            <>
              <h3 style={{ margin: "10px 0 0 0" }}>
                Looking for a karaoke song?
              </h3>
              <h4 style={{ margin: "10px 0 0 0" }}>
                Scan your library to get started.
              </h4>
              <a
                href="/api/connect"
                className="App-spotify-link"
                style={{ marginTop: "30px" }}
              >
                <img
                  src={"/spotify.png"}
                  alt="logo"
                  style={{ height: "20px", marginRight: "10px" }}
                />
                Connect to Spotify
              </a>
            </>
          ))}
        {state.type === "error" && <div>{state.message}</div>}
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          padding: "15px",
          textAlign: "center",
        }}
      >
        <a
          href="/privacy.html"
          target="_blank"
          style={{ color: "#ffffff", textDecoration: "underline" }}
        >
          How does Singable use my data?
        </a>
      </div>
    </div>
  );
}

export default App;
