import { useState } from "react";
import { Button, Input, Message, Modal } from "semantic-ui-react";

export function AccountModal(props: {
  open: boolean;
  currentUsername: string;
  onCancel: () => void;
  onFinish: (newUsername: string) => void;
}) {
  const [username, setUsername] = useState(props.currentUsername);
  const [status, setStatus] = useState<
    { type: "ready" } | { type: "loading" } | { type: "error"; message: string }
  >({ type: "ready" });
  return (
    <Modal onClose={props.onCancel} open={props.open} size="tiny">
      <Modal.Header>Account</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Input
            label="Username"
            value={username}
            onChange={(_e, d) => setUsername(d.value ?? "")}
            style={{ width: "100%" }}
          />
        </Modal.Description>
        {status.type === "error" && <Message error>{status.message}</Message>}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button
          primary
          loading={status.type === "loading"}
          content="Save"
          onClick={() => {
            setStatus({ type: "loading" });
            fetch("/api/users/me", {
              method: "PUT",
              body: JSON.stringify({
                username,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }).then(async (response) => {
              if (response.status === 204) {
                props.onFinish(username);
                setStatus({ type: "ready" });
              } else if (response.status === 409) {
                setStatus({
                  type: "error",
                  message: "That username is already taken",
                });
              } else {
                setStatus({
                  type: "error",
                  message: await response.text(),
                });
              }
            });
          }}
        />
      </Modal.Actions>
    </Modal>
  );
}
